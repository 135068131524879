import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import numeral from 'arts/config/numeral';
import { FORMAT } from '../checkout_summary/checkout_summary';
import Classes from './styles';
import RemoveCartItem from './remove_cart_item';

export const linkTo = (realLinks, href, text) => (realLinks ? (
  <a href={ href }>{ text }</a>
) : (
  <Link to={ href }>
    { text }
  </Link>
));

export const renderItemName = (realLinks, nameHref, name, byHref, by) => (
  <>
    <h4>
      { linkTo(realLinks, nameHref, name) }
    </h4>
    <div className={ Classes.by }>
      By&nbsp;
      { byHref ? linkTo(realLinks, byHref, by) : by }
    </div>
  </>
);

export const renderPrice = (label, price, salesPrice, locale) => {
  if (locale) { numeral.locale(locale); }
  if (!salesPrice) {
    return (
      <p>
        <span>
          { label }
        </span>
        &nbsp;
        { numeral(price).format(FORMAT) }
      </p>
    );
  }
  return (
    <p>
      <span>
        { label }
      </span>
      &nbsp;
      <span className={ Classes.old }>
        { numeral(price).format(FORMAT) }
      </span>
      &nbsp;
      <span className={ Classes.new }>
        { numeral(salesPrice).format(FORMAT) }
      </span>
    </p>
  );
};

export const renderValue = (label, value) => (
  <p>
    <span>
      { label }
    </span>
    &nbsp;
    { value }
  </p>
);

export const renderImage = (realLinks, path, imageUrl, name) => (
  <div className={ Classes.image }>
    {
      linkTo(realLinks, path, (
        <img src={ imageUrl } alt={ name } />
      ))
    }
  </div>
);

const ArtItem = ({
  name,
  path,
  by,
  byPath,
  imageUrl,
  price,
  salesPrice,

  printedOn,
  imageSize,
  paperSize,
  sizeLabel,
  sizeValue,
  frameName,
  finalFramedSize,
  mount,
  mat,
  depth,

  id,
  editable,
  removeCartItem,
  isAvailable,
  nonGlare,

  realLinks,
  locale
}) => {
  // eslint-disable-next-line no-unneeded-ternary
  const renderMat = mat ? mat : '';
  const frameDetails = finalFramedSize ? (
    <>
      { renderValue('Frame:', `${ frameName } ${ mount ? '' : ` ${ renderMat }` }`) }
      {
        mount ? (
          <p>
            { mount }
          </p>
        ) : null
      }
      { printedOn === 'Canvas' ? null : renderValue('Plexi:', nonGlare ? 'Non-glare' : 'Standard') }
      { renderValue('Final Framed Size:', finalFramedSize) }
      { renderValue('Depth:', depth) }
    </>
  ) : (
    <p>
      <span>
        No Frame
      </span>
    </p>
  );

  const availability = isAvailable ? null : (
    <div className={ Classes.error }>
      This item is no longer available
    </div>
  );

  const unframedSize = (finalFramedSize && printedOn !== 'Canvas') ? renderValue('Paper Size (unframed):', `${ paperSize }${ window.currentLocale.unit }`) : null;

  return (
    <div className={ classnames(Classes.item, Classes.classic) }>
      { renderImage(realLinks, path, imageUrl, name) }
      <div className={ Classes.info }>
        { renderItemName(realLinks, path, name, byPath, by) }
        { renderPrice('Price', price, salesPrice, locale) }
        { renderValue('Printed on', printedOn) }
        { finalFramedSize ? null : renderValue(sizeLabel, `${ sizeValue }${ window.currentLocale.unit }`) }
        { renderValue('Image Size:', `${ imageSize }${ window.currentLocale.unit }`) }
        { unframedSize }
        { frameDetails }
        { availability }
      </div>
      { editable ? <RemoveCartItem removeCartItem={ removeCartItem } id={ id } /> : null }
    </div>
  );
};

ArtItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  by: PropTypes.string.isRequired,
  byPath: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  salesPrice: PropTypes.string,
  printedOn: PropTypes.string.isRequired,
  imageSize: PropTypes.string.isRequired,
  paperSize: PropTypes.string.isRequired,
  sizeLabel: PropTypes.string,
  sizeValue: PropTypes.string,
  frameName: PropTypes.string,
  finalFramedSize: PropTypes.string,
  mount: PropTypes.string,
  mat: PropTypes.string,
  depth: PropTypes.string,
  id: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  removeCartItem: PropTypes.func,
  isAvailable: PropTypes.bool.isRequired,
  realLinks: PropTypes.bool,
  nonGlare: PropTypes.bool,
  locale: PropTypes.string.isRequired
};

ArtItem.defaultProps = {
  realLinks: false
};

export default ArtItem;
