import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CartItem from './cart_item';
import Classes from './styles';
import { onCheckoutStep } from '../../config/analytics';

export const multipleItems = (count, extraText = '') => (
  count === 1 ? '1 Item' : ` ${ count } Items`
) + extraText;

const CartItems = ({
  collection, loading, fetchCartItems, removeCartItem,
  removable, summaryOfCharges, narrow, orderId, polling,
  checkoutStep, locale, pendingCartItemsCount
}) => {
  const { merchandise } = summaryOfCharges || {};
  let interval = null;

  useEffect(() => {
    if (
      'merchandise' in summaryOfCharges
      && collection.length === 0
      && removable
      && !loading
    ) {
      window.location.href = '/works';
    }
  }, [
    merchandise
  ]);

  if (orderId) {
    useEffect(() => {
      if (!polling && interval) {
        clearInterval(interval);
        interval = null;
      } else if (polling && !interval) {
        interval = setInterval(() => {
          fetchCartItems(orderId);
        }, 5000);
      }
      return () => {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
      };
    }, [
      polling
    ]);
  }

  useEffect(() => {
    if (collection) {
      onCheckoutStep(checkoutStep, collection);
    }
  }, [collection.size]);

  if (loading) {
    return (
      <Loader active inline>Loading collection...</Loader>
    );
  }

  const notAvailable = collection.find((c) => !c.isAvailable) ? (
    <p className={ Classes.error }>
      Some of the items in you basket are no longer available.
    </p>
  ) : null;

  const itemsCount = pendingCartItemsCount && pendingCartItemsCount > 0 ? (
    <div>
      { multipleItems(pendingCartItemsCount) }
    </div>
  ) : null;

  return (
    <div className={ classnames({ [Classes.narrow]: narrow }) }>
      { notAvailable }
      { itemsCount }
      {
        collection.map(
          (c) => (
            <CartItem
              { ...c }
              key={ c.id }
              editable={ removable }
              removeCartItem={ removeCartItem }
              locale={ locale }
            />
          )
        )
      }
    </div>
  );
};

CartItems.propTypes = {
  loading: PropTypes.bool.isRequired,
  removable: PropTypes.bool,
  collection: PropTypes.array.isRequired,
  fetchCartItems: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func,
  summaryOfCharges: PropTypes.object,
  narrow: PropTypes.bool,
  orderId: PropTypes.number,
  polling: PropTypes.bool,
  checkoutStep: PropTypes.number,
  locale: PropTypes.string,
  pendingCartItemsCount: PropTypes.number
};

export default CartItems;
